// Colors
$port-black: #3a3b3c;
$port-white: #f7f7ff;
$port-purple: #a56be7;
$port-purple-2: #b585eb;
$port-purple-light: #a56be750;
$port-purple-ultralight: #a56be705;
$port-purple-dim: #2b1d36;
$port-green: #0ea8a8;
$port-green-light: #0ea8a840;
$port-green-bright: #0ea8a8;
$primary-gradient: linear-gradient(
  90deg,
  rgba(21, 119, 212, 1) 0%,
  rgba(14, 168, 168, 1) 37%,
  rgba(228, 196, 20, 1) 100%
);
$secondary-gradient: linear-gradient(75deg, #55f5a520 33%, #cba9f120 67%);
// Breakpoints and width
$max-width: 1200px;
$port-extraSmall: 320px;
$port-small: 400px;
$port-medium: 900px;
$port-large: 1200px;

*,
html,
body {
  padding: 0;
  margin: 0;
  cursor: default;
  scroll-behavior: smooth;
}

html {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: $port-black; // Default black
  background-color: $port-white; // Default white
}

body,
section:not(#navbar) {
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 0;
  }
}

h1 {
  font-size: 1rem;
}

h2 {
  font-size: 2.5rem;
  font-weight: 700;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1rem;
}

p {
  font-size: 0.85rem;
}

a {
  text-decoration: none;
  color: inherit;
}

.font-outfit {
  font-family: "Outfit Variable", sans-serif;
}

.color-purple {
  color: $port-purple;
}

.color-green {
  color: $port-green;
}

.color-black {
  color: $port-black;
}

.color-white {
  color: $port-white;
}

.sp-text-green {
  background: $port-green-light;
  background: linear-gradient(to bottom, transparent 50%, $port-green-light 0%);
  transition-duration: 500ms;
}

.sp-text-purple {
  background: $port-purple-light;
  background: linear-gradient(
    to bottom,
    transparent 50%,
    $port-purple-light 0%
  );
  transition-duration: 500ms;
}

.sp-text-gradient {
  background: $port-purple;
  background: $primary-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition-duration: 500ms;
}

.react-icons {
  vertical-align: middle;
}

.glassy-card {
  padding: 1rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  &:hover {
    box-shadow: 0 4px 30px $port-purple-light;
  }
}

.pos-adjust {
  transform: translateY(10%);
}
