@use "../../App.scss";

.tag {
  font-size: 0.75rem;
  display: inline-block;
  padding: 0.1rem 0.5rem;
  margin: 0.2rem 0.2rem 0.2rem 0;
  border-radius: 0.3rem;
  border: 1px solid App.$port-purple-light;
  color: App.$port-purple;
}
