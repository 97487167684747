@use "../../App.scss";

#navbar {
  width: 100%;
  position: fixed;
  z-index: 10;
  & > div {
    max-width: App.$max-width;
    height: 2rem;
    margin: 0 auto;
    padding: 1rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 > a {
      cursor: pointer;
    }
    .navbar-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      min-width: 50px;
      height: 2rem;
      .navbar-item {
        padding: 0 1rem;
        vertical-align: middle;
        position: relative;
        a {
          cursor: pointer;
        }
        @media screen and (max-width: App.$port-medium) {
          display: none;
        }
      }
      img.menu-btn {
        object-fit: contain;
        width: 100%;
        height: 1.5rem;
        max-width: 1.5rem;
        cursor: pointer;
        padding: 0.3rem;
        position: absolute;
        z-index: 15;
        right: 0;
        display: none;
        @media screen and (max-width: App.$port-medium) {
          display: block;
        }
        &.close {
          max-width: 1.2rem;
        }
      }
      .nav-menu-popup {
        padding: 2rem 3rem;
        position: absolute;
        z-index: 10;
        right: -10px;
        top: -10px;
        text-align: center;
        display: none;
        & > h4:not(:last-child) {
          margin-bottom: 1rem;
        }
        h4 > a {
          cursor: pointer;
          &:hover {
            color: App.$port-purple;
          }
        }
        @media screen and (max-width: App.$port-medium) {
          &.open {
            display: block;
          }
        }
      }
    }
  }
}
