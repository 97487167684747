@use "../../../App.scss";

#contact {
  min-height: 100vh;
  background-color: App.$port-purple-dim;
  #contact-view {
    max-width: App.$max-width;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .contact-card {
      width: 100%;
      padding: 6rem 1rem;
      h3.title {
        margin-bottom: 2rem;
      }
      h4 {
        margin-bottom: 0.5rem;
      }
      a {
        cursor: pointer;
      }
      .card-flex {
        display: flex;
        justify-content: center;
        column-gap: 5rem;
        @media screen and (max-width: App.$port-medium) {
          flex-direction: column;
        }
        .contact-list {
          max-width: 330px;
          margin-bottom: 2rem;
          @media screen and (max-width: App.$port-medium) {
            max-width: 100%;
          }
        }
        #contact-form {
          max-width: 330px;
          @media screen and (max-width: App.$port-medium) {
            max-width: 100%;
          }
          form {
            display: flex;
            flex-direction: column;
            min-width: 330px;
            font-family: "Poppins", sans-serif;
            border: none;
            @media screen and (max-width: 330px) {
              width: 100%;
            }
          }
          label {
            margin-bottom: 1rem;
          }
          input,
          textarea {
            font-size: 1rem;
            padding: 0.5em;
            margin-bottom: 1rem;
            border-radius: 0.5em;
            font-family: "Poppins", sans-serif;
          }
          button {
            padding: 0.5rem 1rem;
            border-radius: 0.3em;
            border: none;
            cursor: pointer;
            font-size: 1rem;
            background-color: App.$port-green-bright;
          }
        }
      }
    }
    .ack {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 1rem 0;
      p {
        text-align: center;
      }
    }
    a.ex-link {
      color: App.$port-green-bright;
      cursor: pointer;
    }
  }
}

.success-msg {
  max-width: 340px;
  @media screen and (max-width: App.$port-medium) {
    max-width: 100%;
  }
}
